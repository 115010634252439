import match from '@nerds.sh/js-pattern'

import { statuses } from 'components/metaspaces-carousel/constants/statuses'
import { Content } from 'components/metaspaces-carousel/content'
import { LoadingSpinner } from 'components/loading-spinner'

export const usePickContent = status => match(status)
  .with(statuses.loading, () => LoadingSpinner)
  .with(statuses.valid, () => Content)
  .otherwise(() => '')
