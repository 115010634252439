import storageSession from 'redux-persist/lib/storage/session'
import { persistReducer } from 'redux-persist'
import { transaction } from 'transaction/slices'

const maiarConfig = {
  timeout: 1000,
  key: 'auth:maiar',
  storage: storageSession,
}

export const reducer = persistReducer(maiarConfig, transaction.reducer)
