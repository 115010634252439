import * as loginServices from '@multiversx/sdk-dapp/hooks/login'
import { useCallbackRoute } from 'sidebar/connect/hooks/use-callback-route'
import { useRedirectableLogin } from 'sidebar/connect/hooks/use-redirectable-login'
import { useLayeredNavigate } from 'components/layered-router/hooks/use-layered-navigate'

export const useExtensionLogin = () => {
  const callbackRoute = useCallbackRoute()
  const navigate = useLayeredNavigate()

  const [onInitiateLogin] = loginServices.useExtensionLogin({
    callbackRoute,
    nativeAuth: true,
    onLoginRedirect: route => {
      navigate(route)
    },
  })

  return useRedirectableLogin(() => {
    onInitiateLogin()
  }, [onInitiateLogin])
}
