import { combineReducers } from 'redux'

import { collections as root } from 'collections/slices/collections'
import { lookup } from 'collections/slices/look-up-transaction'
import { assets } from 'collections/assets/slices'
import { persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'
import { auctions } from 'collections/auctions/slices'
import { stats } from 'collections/stats/slices'
import { paymentTokens } from 'collections/payment-tokens/slices'

const lookUpConfig = {
  timeout: 1000,
  key: 'maiar:collections:lookUp',
  storage: storageSession,
}

export const collections = combineReducers({
  collections: root.reducer,
  lookup: persistReducer(lookUpConfig, lookup.reducer),
  assets,
  auctions,
  stats,
  paymentTokens,
})
