import { useEffect } from 'react'
import axios from 'axios'
import { settings } from 'settings/fetching'
import { getNativeAuthToken } from 'network/get-native-auth-token'
import { useDappLogout } from 'logout/hooks/use-dapp-logout'

const shouldHandle = config => {
  const urlMatches = config?.url?.startsWith(settings().elrondApi)

  return urlMatches
}
/* eslint-disable */
export const useSetupInterceptors = () => {
  const token = getNativeAuthToken()
  const logout = useDappLogout()
  
  const fetchToken = async () => axios.get(`${settings().extrasApi}/access`).then((res) => res.data)
  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(async config => {

      if (shouldHandle(config)) {
        let intermToken = token ? token : await fetchToken()
        config.headers.authorization = `Bearer ${intermToken}`
      }

      return config
    })

    const responseInterceptor = axios.interceptors.response.use( response => response, error =>  {
      if (error.response.status === 403 && !error.config.url.startsWith(settings().maiarId)) {
         logout()
      }

      return Promise.reject(error)
    })

    return () => {
      axios.interceptors.request.eject(requestInterceptor)
      axios.interceptors.response.eject(responseInterceptor)
    }
  }, [token])
}
/* eslint-enable */
