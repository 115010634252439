import { ApolloClient, InMemoryCache } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client/public'
import { settings } from 'settings/fetching'

const getBaseUrl = () => settings().nftService
const cacheOptions = {
  typePolicies: {
    Asset: { keyFields: ['identifier'] },
    Account: { keyFields: false },
    Auction: { keyFields: false },
    Order: { keyFields: ['id'] },
  },
}

export const make = () => new ApolloClient({
  link: createUploadLink({ uri: `${getBaseUrl()}/graphql` }),
  cache: new InMemoryCache(cacheOptions),
  defaultOptions: { query: { errorPolicy: 'all', fetchPolicy: 'no-cache' } },
})
