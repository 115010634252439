import PropTypes from 'prop-types'
import { useRestSetup } from 'network/hooks/use-rest-setup'
import { useGraphSetup } from 'network/hooks/use-graph-setup'
import { useInitializeSentry } from 'startup'
import { useInitializeTagManager } from '../startup/initializeTagManager'
import { useSetupInterceptors } from './axios-interceptor/use-elrond-interceptors'

export const Network = ({ children }) => {
  useInitializeSentry()
  useInitializeTagManager()
  useSetupInterceptors()
  const wasRestSetup = useRestSetup()
  const wasGraphSetup = useGraphSetup()

  return wasRestSetup && wasGraphSetup ? children : ''
}

Network.propTypes = { children: PropTypes.node.isRequired }

export * from 'network/actions'
export { rest } from 'network/rest'
export { graph } from 'network/graph'
export { history } from 'network/history'
