import { useUpdateErrors as useDescriptionErrors } from 'modals/account/edit/description/hooks/use-update-errors'
import { useUpdateErrors as useLinksErrors } from 'modals/account/edit/social-links/hooks/use-update-errors'
import { useUpdateErrors as usePrivacyErrors } from 'modals/account/edit/privacy/hooks/use-update-errors'

export const useErrors = () => {
  const descriptionErrors = useDescriptionErrors()
  const linksErrors = useLinksErrors()
  const privacyErrors = usePrivacyErrors()

  return [...descriptionErrors, ...linksErrors, ...privacyErrors]
}
