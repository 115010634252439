import { Behaviour } from 'home/metaspaces/behaviour'
import { HomeSection } from 'components/home-section'
import { useMetaspacesContent } from 'home/metaspaces/hooks/use-metaspaces-content'
import { MetaspacesCarousel } from 'components/metaspaces-carousel'

const description = 'Unique value propositions, delivered in a remarkable way.'

export const Metaspaces = () => {
  const items = useMetaspacesContent()

  return <>
    <Behaviour />
    <HomeSection title={'Featured Projects'} description={description}>
      <MetaspacesCarousel items={items} />
    </HomeSection>
  </>
}
