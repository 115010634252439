import * as loginServices from '@multiversx/sdk-dapp/hooks/login'
import { useCallbackRoute } from 'sidebar/connect/hooks/use-callback-route'

export const useWalletLogin = () => {
  const callbackRoute = useCallbackRoute()
  const [onInitiateLogin] = loginServices.useWebWalletLogin({
    callbackRoute,
    nativeAuth: true,
    redirectDelayMilliseconds: 100,
  })

  return onInitiateLogin
}
