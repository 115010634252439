import { gql } from 'graphql-tag'

export const ASSETS = gql`
    query assets($filters: AssetsFilter, $pagination: ConnectionArgs) {
      assets(filters: $filters, pagination: $pagination)
  {
    edges {
       cursor
       node{
         auctions{
            edges{
                cursor
                node{
                    availableTokens
                    endDate
                    id
                    identifier
                    maxBid{
                        amount
                        token
                        usdAmount
                        tokenData{
                            decimals
                            symbol
                        }
                    }
                    minBid{
                        amount
                        token
                        usdAmount
                        tokenData{
                            decimals
                            symbol
                        }
                    }
                    nrAuctionedTokens
                    orders{
                        edges{
                            cursor
                            node{
                                auctionId
                                endDate
                                from{
                                    address
                                    herotag
                                    profile
                                }
                                id
                                ownerAddress
                                price{
                                    amount
                                    token
                                    usdAmount
                                    tokenData{
                                        decimals
                                        symbol
                                    }
                                }
                            }
                        }
                        pageData{
                            count
                        }
                        pageInfo{
                            endCursor
                            hasNextPage
                            hasPreviousPage
                            startCursor
                        }
                    }
                    owner{
                        address
                        herotag
                        profile
                    }
                    ownerAddress
                    topBid{
                        amount
                        token
                        usdAmount
                        tokenData{
                            decimals
                            symbol
                        }
                    }
                    topBidder{
                        address
                    }
                    type
                }
            }
            pageData{
                count
            } 
            pageInfo{
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
            } 
         }
         lowestAuction{
           availableTokens
           endDate
           id
           identifier
           maxBid{
               amount
               token
               usdAmount
               tokenData{
                   decimals
                   symbol
               }
           }
           minBid{
               amount
               token
               usdAmount
               tokenData{
                   decimals
                   symbol
               }
           }
           nrAuctionedTokens
           orders{
               edges{
                   cursor
                   node{
                       auctionId
                       endDate
                       from{
                           address
                           herotag
                           profile
                       }
                       id
                       ownerAddress
                       price{
                           amount
                           token
                           usdAmount
                           tokenData{
                               decimals
                               symbol
                           }
                       }
                   }
               }
               pageData{
                   count
               }
               pageInfo{
                   endCursor
                   hasNextPage
                   hasPreviousPage
                   startCursor
               }
           }
           ownerAddress
           owner{
               address
               herotag
               profile
           }
           topBid{
               amount
               token
               usdAmount
               tokenData{
                   decimals
                   symbol
               }
           }
           topBidder{
               address
           }
           type
         }  
         balance
         collection
         creationDate
         creatorAddress
         creator{
           address
           description
           herotag
           profile
           socialLinks{
             type
             url
           }
         }
         identifier
         metadata{
           description
         }
         media {
           fileType
           thumbnailUrl
           url
         }
         name
         ownerAddress
         owner{
           address
           description
           herotag
           profile
           socialLinks{
             type
             url
           }
         }
         royalties
         tags
         supply
         type
         uris
      }
    }
    pageData{
      count
    }
    pageInfo{
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
 }
`
