import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'

export const Button = ({ link }) => <div className={'col-12 col-md-auto'}>
  <a className={'btn btn-primary'} href={link} target={'_blank'} rel={'noreferrer'}>
    Enter Metaspace
    {' '}
    <FontAwesomeIcon icon={faArrowRight} />
  </a>
</div>
