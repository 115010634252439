import { from, throwError, of } from 'rxjs'
import { getAddress } from '@multiversx/sdk-dapp/utils'
import { catchError, mergeMap } from 'rxjs/operators'
import { settings } from 'settings/fetching'
import { BN } from 'bn.js'
import { calculateFee } from 'common/calculate-fee'
import { rest } from 'network'
import { denominateAmount } from 'common/conversion/denominate-amount'

const egldLabel = () => settings().dapp.egldLabel

const hasEnoughBalance = (data, balance, factor) => {
  const multiplyFactor = new BN(String(calculateFee(data))).mul(new BN(String(factor)))
  const totalValue = new BN(String(multiplyFactor)).add(new BN(String(data.value)))
    .toString()
  const totalCosts = parseFloat(denominateAmount(totalValue))
  const formattedTotalValue = denominateAmount(totalValue, 18, true)
  const error = { graphQLErrors: [{ message: `Not enough balance - Required ${formattedTotalValue} ${egldLabel()}` }] }

  return totalCosts <= parseFloat(denominateAmount(balance)) ? of(data) : throwError(error)
}

const makeRestError = error => {
  if (error.graphQLErrors) {
    return error
  }

  return ({ result: { networkError: { result: { errors: [{ message: error.statusText }] } } } })
}

export const throwOnInsufficientFunds = ({ data, factor = 1 }) => from(getAddress())
  .pipe(mergeMap(address => rest().get(`${settings().elrondApi}/accounts/${address}`)))
  .pipe(mergeMap(response => of(response.data)))
  .pipe(mergeMap(({ balance }) => hasEnoughBalance(data, balance, factor)))
  .pipe(catchError(error => throwError(makeRestError(error))))
