import SwiperCore, { Pagination, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { Item } from 'components/metaspaces-carousel/item'
import { useRef } from 'react'
import 'swiper/swiper.scss'
import 'swiper/modules/pagination/pagination.scss'

SwiperCore.use([Pagination, Navigation])

export const Content = ({ items }) => {
  const paginationRef = useRef(null)

  return <div className={'container'}>

    <Swiper slidesPerView={'auto'} centeredSlides pagination={{ el: paginationRef.current }}
            navigation
            spaceBetween={12} className={'metaspaces-carousel'}
            breakpoints={{ 768: { spaceBetween: 32 } }}>
      {
        items?.map((item, index) => <SwiperSlide key={index}>
          <Item item={item} />
        </SwiperSlide>)
      }
      <div className={'swiper-pagination'} ref={paginationRef} />
      <div className={'swiper-gradient-start'} />
      <div className={'swiper-gradient-end'} />
    </Swiper>
  </div>
}
