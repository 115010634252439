import { get } from 'lodash'
import { Logo } from 'components/metaspaces-carousel/item/logo'
import { Title } from 'components/metaspaces-carousel/item/title'
import { Description } from 'components/metaspaces-carousel/item/description'
import { Button } from 'components/metaspaces-carousel/item/button'

export const Item = ({ item }) => {
  const title = get(item, 'attributes.title', '')
  const description = get(item, 'attributes.description', '')
  const link = get(item, 'attributes.link', '')
  const logoUrl = get(item, 'attributes.logo.data.attributes.url', '')
  const backgroundUrl = get(item, 'attributes.background.data.attributes.url', '')

  return <div className={'metaspaces-carousel-item'} style={{ backgroundImage: `url(${backgroundUrl})` }}>
    <div className={'metaspaces-carousel-item-gradient'} />
    <div className={'row align-items-end flex-md-nowrap position-relative'}>
      <div className={'col'}>
        <Logo logoUrl={logoUrl} />
        <div className={'metaspaces-carousel-item-text'}>
          <Title title={title} />
          <Description description={description} />
        </div>
      </div>
      <Button link={link} />
    </div>
  </div>
}
