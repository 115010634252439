import { STEP } from 'sidebar/connect/constants'
import 'sidebar/connect/maiar-login/qr-code.scss'
import { useContext } from 'sidebar/connect/context'
import { ConnectButton } from 'sidebar/connect/connect-button'
import { useSetStep } from 'sidebar/connect/hooks/use-set-step'
import { useCallbackRoute } from 'sidebar/connect/hooks/use-callback-route'
import { WalletConnectLoginContainer } from '@multiversx/sdk-dapp/UI'

export const QrCode = () => {
  const callbackRoute = useCallbackRoute()

  const { step } = useContext()
  const handleBack = useSetStep(STEP.loginButtons)

  return step === STEP.maiarQrCode && <div className={'maiar-qr-code'}>
    <WalletConnectLoginContainer wrapContentInsideModal={false} isWalletConnectV2
                                 callbackRoute={callbackRoute} nativeAuth
                                 loginButtonText={'xPortal App'}
                                 title={'xPortal App Login'}
                                 lead={'Scan using the xPortal App'} />
    <ConnectButton LeftIcon={ConnectButton.Icon.ArrowLeft} RightIcon={ConnectButton.Icon.None} onClick={handleBack}>
      Back
    </ConnectButton>
  </div>
}
