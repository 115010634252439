import { fromFetch } from 'rxjs/fetch'
import { settings } from 'settings/fetching'
import { mergeMap, map, reduce, from } from 'rxjs'
import { get } from 'lodash'

const makeUrl = address => `${settings().maiarId}/api/v1/users/${address}`

const formatResponse = address => data => [
  address,
  {
    ...data,
    herotag: get(data, 'herotag') ? data.herotag.split('.elrond')[0] : '',
    address,
    media: [],
  },
]

const getAccount = address => fromFetch(makeUrl(address))
  .pipe(mergeMap(res => from(res.json())))
  .pipe(map(formatResponse(address)))

export const accounts = ({ events, field, target }) => {
  const addresses = [...new Set(events.map(event => event[target]))]

  return from(addresses)
    .pipe(mergeMap(getAccount))
    .pipe(reduce((accumulator, [address, data]) => ({ ...accumulator, [address]: data }), {}))
    .pipe(map(result => events.map(event => ({ ...event, [field]: result[event[target]] }))))
}
