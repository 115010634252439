import { useCallback } from 'react'
import { useSettings } from '../../../../settings/hooks/use-settings'

const isFirefox = () => navigator.userAgent.toLowerCase().indexOf('firefox') !== -1

const makeUrl = extensionId => {
  if (isFirefox()) {
    return 'https://addons.mozilla.org/en-US/firefox/addon/maiar-defi-wallet'
  } else {
    return `https://chrome.google.com/webstore/detail/maiar-defi-wallet/${extensionId}`
  }
}

export const useNavigateToStore = () => {
  const { extensionId } = useSettings()

  return useCallback(() => {
    window.open(makeUrl(extensionId), '_blank')
  }, [extensionId])
}
