import { ConnectButton } from 'sidebar/connect/connect-button'
import { useWalletLogin } from 'sidebar/connect/hooks/use-wallet-login'

export const WalletLogin = () => {
  const walletLogin = useWalletLogin()

  return <ConnectButton LeftIcon={ConnectButton.Icon.Elrond} onClick={walletLogin}>
    MultiversX Web Wallet
  </ConnectButton>
}
