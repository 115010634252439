import SwiperCore, { Mousewheel, Pagination, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { Item } from 'components/marketplaces-carousel/item'
import { useRef } from 'react'
import 'swiper/modules/pagination/pagination.scss'

SwiperCore.use([Mousewheel, Pagination, Navigation])

const makeProps = (paginationRef, nextRef, prevRef) => ({
  className: 'marketplaces-carousel-carousel',
  breakpoints: {
    768: { spaceBetween: 32, slidesPerView: 1.5 },
    992: { slidesPerView: 2 },
    1200: { slidesPerView: 3 },
  },
  slidesPerView: 1.1,
  spaceBetween: 12,
  pagination: { el: paginationRef.current },
  navigation: { prevEl: prevRef.current, nextEl: nextRef.current },
})

export const Content = ({ items }) => {
  const paginationRef = useRef(null)
  const prevRef = useRef(null)
  const nextRef = useRef(null)

  return <div className={'marketplaces-carousel'}>
    <div className={'container'}>
      <div className={'marketplaces-carousel-inner'}>
        <div className={'swiper-button-prev'} ref={prevRef} />
        <div className={'swiper-button-next'} ref={nextRef} />
        <Swiper {...makeProps(paginationRef, nextRef, prevRef)}>
          {items.map((item, index) => <SwiperSlide key={index}>
            <Item item={item} />
          </SwiperSlide>)}
          <div className={'swiper-pagination'} ref={paginationRef} />
        </Swiper>
      </div>
    </div>
  </div>
}
