import { replace } from 'lodash'
import { settings } from 'settings/fetching'

export const getMainnetThumbnailApi = () => {
  const isMainnet = process.env.REACT_APP_NODE_ENV === 'mainnet'
  const isStaging = process.env.REACT_APP_NODE_ENV === 'staging'
  const replacedApi = replace(settings().elrondApi, 'internal-api', 'api')

  return isMainnet || isStaging ? replacedApi : settings().elrondApi
}
