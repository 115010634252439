import match from '@nerds.sh/js-pattern'
import { Video } from 'nft-page/media/video'
import { Image } from 'nft-page/media/image'
import { Audio } from 'nft-page/media/audio'
import { NotSupported } from 'nft-page/media/not-supported'
import { get, isEmpty } from 'lodash'

export const useFileType = media => {
  const fileType = get(media, 'fileType', '')
  const deprecatedFileType = get(media, 'metadata.fileType', '')
  const defaultFileType = ''

  return fileType || deprecatedFileType || defaultFileType
}

const matchFileType = fileType => match(fileType)
  .with(type => type.startsWith('video'), () => Video)
  .with(type => type.startsWith('image'), () => Image)
  .with(type => type.startsWith('audio'), () => Audio)
  .otherwise(() => NotSupported)

export const useComponent = media => {
  const fileType = useFileType(media)

  return isEmpty(media) ? () => '' : matchFileType(fileType)
}
