import { rest } from 'network'
import { settings } from 'settings/fetching'

const getBaseUrl = () => settings().maiarId

const updateDescription = description => rest().put(`${getBaseUrl()}/api/v1/self/description`, {
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(description),
}, true)

const setProfilePhoto = profilePhoto => {
  const body = new window.FormData()
  body.append('file', profilePhoto)

  return rest().post(`${getBaseUrl()}/api/v1/self/profile-photo`, {
    headers: { accept: 'application/json' },
    body,
  }, true)
}

const setCoverPhoto = coverPhoto => {
  const body = new window.FormData()
  body.append('file', coverPhoto)

  return rest().post(`${getBaseUrl()}/api/v1/self/cover-photo`, {
    headers: { accept: 'application/json' },
    body,
  }, true)
}

const setSocialLink = socialLink => rest().post(`${getBaseUrl()}/api/v1/self/social-link`, {
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(socialLink),
}, true)

const users = address => rest().get(`${getBaseUrl()}/api/v1/users/${address}`, {
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
}, false)

const multipleUsers = addresses => rest().post(`${getBaseUrl()}/api/v1/users/multiple`, {
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(addresses),
}, false)

const selfInfo = () => rest().get(`${getBaseUrl()}/api/v1/self/info`, {
  method: 'GET',
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
}, true)

const socialLinks = () => rest().get(`${getBaseUrl()}/api/v1/social-links`, {
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
}, true)

const privacy = () => rest().get(`${getBaseUrl()}/api/v1/self/privacy`, {
  method: 'GET',
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
}, true)

const updatePrivacy = privacy => rest().post(`${getBaseUrl()}/api/v1/self/privacy`, {
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(privacy),
}, true)

export const identity = () => ({
  self: {
    updateDescription,
    setProfilePhoto,
    setCoverPhoto,
    setSocialLink,
    privacy,
    updatePrivacy,
  },
  users,
  multipleUsers,
  selfInfo,
  socialLinks,
})
