import storageSession from 'redux-persist/lib/storage/session'
import { persistReducer } from 'redux-persist'
import { actionStatuses as slice } from './index'

const maiarConfig = {
  timeout: 1000,
  key: 'statuses:maiar',
  storage: storageSession,
}

export const actionStatuses = persistReducer(maiarConfig, slice.reducer)
