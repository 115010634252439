import { persistReducer } from 'redux-persist'
import { preferences as set } from './slices'
import { CookieStorage } from 'redux-persist-cookie-storage'
import Cookies from 'cookies-js'

const preferencesConfig = {
  key: 'preferences:maiar',
  storage: new CookieStorage(Cookies),
}

export const preferences = persistReducer(preferencesConfig, set.reducer)
